<template>
  <v-card flat>
    <v-form
      ref="form"
    >
      <v-row>

        <v-col
          cols="12"
        >
          <v-text-field
            v-model="form.contractName"
            :label="$vuetify.lang.t('$vuetify.contract.contractName')"
            :rules="rules.contractName"
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
        >
          <v-text-field
            v-model="equTemplateName"
            :label="$vuetify.lang.t('$vuetify.equipmentTemplate.label')"
            :rules="rules.equTemplateId"
            readonly
            @click="handleEquTemplateIdClick"
          ></v-text-field>
        </v-col>

        <v-col
          class="pl-0"
          cols="12"
        >
          <v-subheader>
            {{ $vuetify.lang.t('$vuetify.contract.serviceType') }}
          </v-subheader>
        </v-col>

        <v-col
          v-for="(item, index) in serviceTypeItems"
          :key="`service-type-${index}`"
          cols="6"
        >
          <service-card
            :service="item"
            :service-items="index === 0 ? serviceItemItems : []"
            :service-list-selected="getServiceListSelected(item.value)"
            :selected="getServiceListSelected(item.value).length > 0"
            @change="handleServiceCardChange($event, item)"
          />
        </v-col>
      </v-row>
    </v-form>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="info darken-1"
        @click="handleCancel"
      >
         {{$vuetify.lang.t('$vuetify.common.cancel')}}
      </v-btn>
      <v-btn
        color="primary darken-1"
        @click="handleSubmit"
      >
         {{$vuetify.lang.t('$vuetify.common.submit')}}
      </v-btn>
    </v-card-actions>
    <v-dialog
      v-model="equipmentTemplateDialog"
      persistent
      max-width="800px"
    >
      <equipment-template-list
        @submit="handleEquipmentTepmalteSubmit"
        @cancel="handleEquipmentTepmalteCancel"
      />
    </v-dialog>
  </v-card>
</template>

<script>

  import { mapGetters, mapActions } from 'vuex';

  export default {
    components: {
      ServiceCard: () => import('./ServiceCard'),
      EquipmentTemplateList: () => import('../equipment/template'),
    },
    props: {
      editStatus: {
        type: Boolean,
        default: () => false
      },
      editItem: {
        type: Object,
        default: () => null
      }
    },
    data() {
      return {
        equipmentTemplateDialog: false,

        serviceTypeItems: [
          {
            name: this.$vuetify.lang.t('$vuetify.serviceType.reportService'),
            value: 'REPORT_SERVICE'
          },
          {
            name: this.$vuetify.lang.t('$vuetify.serviceType.sosAlarm'),
            value: 'SOS_ALARM'
          },
          {
            name: this.$vuetify.lang.t('$vuetify.serviceType.healthConsultation'),
            value: 'HEALTH_CONSULTATION'
          }
        ],

        serviceItemItems: [
          {
            name: this.$vuetify.lang.t('$vuetify.serviceItem.healthReport'),
            value: 'HEALTH_REPORT'
          },
          {
            name: this.$vuetify.lang.t('$vuetify.serviceItem.ecgStandard'),
            value: 'ECG_STANDARD'
          },
          {
            name: this.$vuetify.lang.t('$vuetify.serviceItem.ecgExpert'),
            value: 'ECG_EXPERT'
          }
        ],

        form: {
          id: null,
          contractName: null,
        },

        equipmentTemp: null,

        serviceList: [],

        rules: {
          contractName: [
            (v) =>
              !!v || this.$vuetify.lang.t('$vuetify.rule.required', [this.$vuetify.lang.t('$vuetify.contract.contractName')])
          ],
          equTemplateId: [
            (v) =>
              !!v || this.$vuetify.lang.t('$vuetify.rule.selected', [this.$vuetify.lang.t('$vuetify.equipmentTemplate.label')])
          ]
        }
      }
    },

    computed: {
      ...mapGetters({
        regions: 'region/regions'
      }),

      equTemplateName () {
        if (this.equipmentTemp) {
          return `${ this.equipmentTemp.name } - ${ this.equipmentTemp.no }`
        } else {
          return null
        }
      }
    },

    created () {
      this.initialize()
    },

    methods: {

      ...mapActions({
        contractCreate: 'contract/create'
      }),

      initialize () {
        if (this.editStatus) {
          for (let p in this.form) {
            if (p in this.editItem) {
              this.form[p] = this.editItem[p]
            } else {
              this.form[p] = null
            }
          }
          if (this.editItem.equipmentTemp) {
            this.equipmentTemp = this.editItem.equipmentTemp
          }
          this.serviceList = this.editItem.serviceList ? this.editItem.serviceList : []
        } else {
          this.resetFrom()
        }
      },

      getServiceListSelected (serviceType) {
        return this.serviceList.filter( element => element.serviceType === serviceType)
      },

      resetFrom () {
        for (let key in this.form) {
          this.form[key] = null
        }
        this.form.credentialType = 'I'
        this.$nextTick ( () => {
          this.$refs.form.resetValidation()
        })

      },
      handleCancel() {
        this.$router.go(-1)
      },

      handleSubmit() {
        if (this.$refs.form.validate()) {
          const data = {}
          for (let key in this.form) {
            data[key] = this.form[key]
          }
          data.equTemplateId = this.equipmentTemp.id
          data.serviceList = this.serviceList
          if (this.editStatus) {
            this.$emit('update', data);
          } else {
            this.contractCreate(data).then( () => {
              this.$router.push({ name: "contractList" })
            })
          }
        }
      },

      handleServiceCardChange (data, item) {
        this.serviceList = this.serviceList.filter( element => element.serviceType !== item.value)
        this.serviceList = this.serviceList.concat(data)
      },

      handleEquTemplateIdClick () {
        this.equipmentTemplateDialog = true
      },

      handleEquipmentTepmalteSubmit (data) {
        this.equipmentTemp = data
        this.equipmentTemplateDialog = false
      },

      handleEquipmentTepmalteCancel () {
        this.equipmentTemplateDialog = false
      }
    }
  }
</script>
